type CustomerLogo = {
  name: string
  image: string
  size?: 'sm' | 'md'
}

export const CustomerLogos: CustomerLogo[] = [
  { name: 'Census', image: 'census.svg' },
  { name: 'Condé Nast', image: `conde-nast.svg` },
  { name: 'Perplexity', image: 'perplexity.svg' },
  { name: 'Ilia', image: 'ilia.svg', size: 'sm' },
  { name: 'Ritual', image: 'ritual.svg', size: 'sm' },
  { name: 'Incident.io', image: 'incidentio.svg' },
  { name: 'Zip', image: 'zip.svg', size: 'sm' },
  { name: 'Hightouch', image: 'hightouch.svg' },
  { name: 'Prefect', image: 'prefect.png' },
  { name: 'Tickpick', image: 'tickpick.png' },
  { name: 'Game lounge', image: 'gamelounge.svg' },
  { name: 'AACSB', image: 'aacsb.svg' },
  { name: 'Lyka', image: 'lyka.svg', size: 'sm' },
  { name: 'Front', image: 'front.svg' },
  { name: 'Dagster', image: 'dagster.svg' },
]

const logoPath = '/images/customer-logos/'

export const customerLogos = {
  aviatrix: `${logoPath}/aviatrix.svg`,
  ascend: `${logoPath}/ascend.svg`,
  aura: `${logoPath}/aura.svg`,
  bluecharm: `${logoPath}/bluecharm.png`,
  census: `${logoPath}/census.png`,
  dagster: `${logoPath}/dagster.png`,
  digitalTrends: `${logoPath}/digitaltrends.svg`,
  driveMyWay: `${logoPath}/drive-my-way.png`,
  ebh: `${logoPath}/ebh.png`,
  foxglove: `${logoPath}/foxglove.png`,
  glass: `${logoPath}/glass.png`,
  incidentio: `${logoPath}/incidentio.svg`,
  maze: `${logoPath}/maze.png`,
  middleware: `${logoPath}/middleware.svg`,
  mileiq: `${logoPath}/mileiq.png`,
  prefect: `${logoPath}/prefect.png`,
  primaryBid: `${logoPath}/primarybid.svg`,
  roseRocket: `${logoPath}/rose-rocket.svg`,
  sifflet: `${logoPath}/sifflet.svg`,
  smartAccess: `${logoPath}/smart-access.png`,
  smugmug: `${logoPath}/smugmug.png`,
  storyworth: `${logoPath}/storyworth.png`,
  superorder: `${logoPath}/superorder.png`,
  tapcheck: `${logoPath}/tapcheck.png`,
  theRounds: `${logoPath}/the-rounds.svg`,
  torotms: `${logoPath}/torotms.png`,
  trint: `${logoPath}/trint.svg`,
  uscreen: `${logoPath}/uscreen.svg`,
}
